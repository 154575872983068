.Navigation {
  width: 100vw;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
}

.btn {
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  position: relative;
  display: inline-block;
  color: white;
  text-decoration: none;
  overflow: hidden;
  transition: 1s all ease;

  &:hover {
    transform: scale(1.3);
    transition: all 0.3s ease-in-out;
  }
}

.Active {
  transform: scale(1.3);
  transition: all 0.3s ease-in-out;
}

@media (max-width: 600px) {
  .btn {
    font-size: 1rem;
  }
}
