.ContactCard {
  margin: auto;
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s linear;
  overflow: hidden;
}

.card {
  padding: 3rem 6rem;
  background: rgba(92, 90, 90, 0.616);
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.4);
    transition: transform 0.3s ease-in-out;

    span {
      color: rgb(212, 212, 21);
    }
  }
}

.card-head {
  margin-bottom: 2rem;
}

.card-body {
  line-height: 1.7;
}
