.MyWork {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  .ProjectsGrid {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    min-height: 100%;
  }
}

@media (max-width: 1100px) {
  .MyWork {
    background: linear-gradient(
      42deg,
      rgba(210, 185, 76, 1) 9%,
      rgba(9, 9, 121, 0.5340511204481793) 47%,
      rgba(255, 113, 0, 0.6292892156862745) 90%,
      rgba(212, 211, 3, 0.6348914565826331) 95%
    );
    .ProjectsGrid {
      grid-template-columns: repeat(2, 2fr);
    }
  }
}
@media (max-width: 650px) {
  .MyWork {
    background: rgba(228, 135, 135, 0.438);
    .ProjectsGrid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
