.ProjectDetail {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0.5rem auto;
  background: linear-gradient(
    42deg,
    rgba(255, 115, 0, 0.336) 90%,
    rgba(212, 212, 3, 0.556) 95%
  );

  .Content {
    text-align: center;
    color: #fff;
  }

  .Video {
    iframe {
      width: 90vw;
      height: 200px;
    }
  }

  .meta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        color: white;
        font-size: 1rem;
        border-radius: 5px;
        padding: 1rem;
        margin: 0.3rem 0.5rem;
        background: rgba(87, 211, 87, 0.486);
      }
    }
  }

  .github {
    button {
      margin-top: 0.5rem;
      position: relative;
      outline: none;
      border: none;
      border-radius: none;
      font-size: 1.5rem;
      padding: 1rem 3rem;
      letter-spacing: 1px;
      transition: all 0.5s ease-in-out;

      &:hover {
        background: #333;
        color: #fff;
        transition: all 0.5s ease-in-out;
        box-shadow: 0 0 8px 4px #eee;
      }
    }
  }
}

@media (min-width: 920px) {
  .ProjectDetail {
    position: relative;
    max-width: 1100px;
    width: 100vw;
    height: 480px;
    margin: 3rem auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: rgba(83, 78, 78, 0.5);
    box-shadow: 10px 10px 15px 6px rgba(238, 238, 238, 0.5);

    .Content {
      position: absolute;
      width: 40%;
      left: 50px;
      top: 15px;
      text-align: left;
      color: #fff;
    }

    .Video {
      position: absolute;
      top: -50px;
      right: 25px;
      box-shadow: 3px 3px 12px 6px rgba(223, 123, 41, 0.342);

      iframe {
        width: 500px;
        height: 300px;
      }
    }

    .meta {
      position: absolute;
      bottom: 100px;
      right: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      ul {
        width: 500px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
          color: white;
          font-size: 1.2rem;
          border-radius: 5px;
          padding: 1rem;
          margin: 0.3rem;
          background: rgba(87, 211, 87, 0.486);
        }
      }
    }

    .github {
      position: absolute;
      bottom: 100px;
      left: 85px;

      button {
        position: relative;
        outline: none;
        border: none;
        border-radius: none;
        font-size: 1.5rem;
        padding: 1rem 3rem;
        letter-spacing: 1px;
        box-shadow: none;
        transition: all 0.5s ease-in-out;

        &:hover {
          background: #333;
          color: #fff;
          transition: all 0.5s ease-in-out;
          box-shadow: 0 0 8px 4px #eee;
        }
      }
    }
  }
}
