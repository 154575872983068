.ProjectSnippet {
  position: relative;
  transition: all 0.5s ease-in-out;
  background-color: white;
  z-index: 99;
  animation: fadeIn 1s linear;

  &:hover {
    transform: scale(1.3);
    transition: transform 0.5s ease-in-out;
    z-index: 100;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 1.5rem;
  color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s ease-in-out;

  &:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 1.5rem;
    color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: rgba(221, 210, 50, 0.863);
    transition: all 0.5s ease-in-out;
  }
}
